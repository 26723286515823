// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-articles-graphql-js": () => import("/opt/build/repo/src/templates/articles-graphql.js" /* webpackChunkName: "component---src-templates-articles-graphql-js" */),
  "component---src-templates-article-graphql-js": () => import("/opt/build/repo/src/templates/article-graphql.js" /* webpackChunkName: "component---src-templates-article-graphql-js" */),
  "component---src-templates-tag-graphql-js": () => import("/opt/build/repo/src/templates/tag-graphql.js" /* webpackChunkName: "component---src-templates-tag-graphql-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

